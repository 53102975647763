<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="table"
        :api="m.api"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: { lead_id: id },
          sort: { key: 'id', order: 'DESC' },
          paramName: 'leadActions',
        }"
        @click="onClickRow($event)"
      >
        <template v-slot:item.type="{ row }">
          <a-view-table-record v-if="row.data && row.data.link" :model="{ direct: true }" :value="row.data.link" />
        </template>
      </a-table-f-api>
    </v-card-text>
  </div>
</template>

<script>
import leads from "@/mixins/leads";
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel, leads],
  components: {},
  props: {
    id: Number,
    data: Object,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.leads.actions,
      task_: null,
    };
  },
  created() {},
  watch: {
    showDialog(v) {
      if (!v) {
        this.$refs.table.updateData();
      }
    },
  },
  computed: {
    url() {
      return this.m.api;
    },
    model() {
      this.makeTasks();
      let model = this.getModelList(this.m);
      model.find((m) => m.name == "type").options = this.task_ || [];
      return model;
    },
  },
  methods: {
    async makeTasks(f) {
      if (!this.task_) this.task_ = await this.getTasks(f);
      return this.task_;
    },
    onClickRow(d) {
      return;
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      this.showEditDialogGoodsFunc(d.row.id);
    },
  },
};
</script>
